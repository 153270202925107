import React from "react";
import Root from "../components/Root";
import SeoAdFree from "../components/seoAdFree";

import Container from "@material-ui/core/Container";
import { Link } from "gatsby-theme-material-ui";
import Typography from "@material-ui/core/Typography";

const NotFound = () => {
  return (
    <Container maxWidth="lg" style={{ marginTop: 80, minHeight: 600 }}>
      <Typography variant="h4" color="textPrimary" style={{ fontWeight: "700", fontSize: "2rem" }}>
        NOT FOUND {":<"}
      </Typography>
      <Typography variant="subtitle2" color="textPrimary">
        You just hit a route that doesn&#39;t exist... the sadness.
      </Typography>

      <Link to={"/"}>
        <Typography variant="subtitle1" style={{ marginTop: 40 }}>
          Go Home
        </Typography>
      </Link>
    </Container>
  );
};

const NotFoundPage = () => (
  <Root>
    <SeoAdFree title="404: Not found" />
    <NotFound />
  </Root>
);

export default NotFoundPage;
